<template>
  <div class="user">
    <el-button type="primary" @click="$router.back()">返回</el-button>
    <div class="title_div">
      <p class="user_p">用户管理</p>
    </div>
    <div class="contentBox">
      <Table
        class="userAdmin"
        ref="tableComponent"
        :popperClass="`paginaClass`"
        :table-data="tableData"
        :columns="columns"
        :total="pageList.count"
        :page.sync="pageList.page"
        :rows.sync="pageList.rows"
        :loading="false"
        :flag="false"
        :searchLeftShow="true"
        :leftShow="false"
        border
        @pagination="getPage"
        @searchList="getPage"
      >
        <!-- 右上角按钮 -->
        <template v-slot:searchRight>
          <el-button type="primary" @click="addBut(false)">新增</el-button>
        </template>

        <!-- 列表展示项 -->
        <template slot="columnEspecial" slot-scope="{ column, row }">
          <span v-if="column.prop === 'isAlive'">{{ row[column.prop] === 1 ? "有效" : "无效" }}</span>
          <span v-else-if="column.prop === 'canDownload'">{{ row[column.prop] === 1 ? "是" : "否" }}</span>
          <span v-else>{{row[column.prop]}}</span>
        </template>

        <!-- 操作栏 -->
        <template slot="columnRight">
          <el-table-column label="操作" fixed="right" align="center">
            <template slot-scope="{ row }">
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="addBut(true, row)"
                >编辑</el-button
              >
              <el-button
                v-show="row.status != '1'"
                type="text"
                size="mini"
                icon="el-icon-refresh"
                @click="userReset(row)"
                >重置</el-button
              >
              <el-button
                type="text"
                size="mini"
                icon="el-icon-unlock"
                @click="removeForbidden(row)"
                >解锁</el-button
              >
            </template>
          </el-table-column>
        </template>
      </Table>
    </div>

    <el-dialog
      :title="isEditor === 1 ? '编辑用户' : '新增用户'"
      :visible.sync="dialogVisible"
      width="25%"
      center
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="账号" prop="account">
            <el-input
              v-model="ruleForm.account"
              :disabled="isEditor"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户名" prop="username">
            <el-input v-model="ruleForm.username"></el-input>
          </el-form-item>
          <el-form-item label="用户是否有效" prop="isAlive">
            <!-- <el-radio-group v-model="ruleForm.isAlive">
              <el-radio label="有效"></el-radio>
              <el-radio label="无效"></el-radio>
            </el-radio-group> -->
            <el-switch
              v-model="ruleForm.delivery"
              @change="switchChange"
            ></el-switch>
          </el-form-item>
          <el-form-item label="是否有下载权限" prop="canDownload">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="ruleForm.canDownload"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Table from "@/components/Table";
import {
  userGetPage,
  createUser,
  updateUser,
  userReset,
  removeForbidden,
} from "@/api/users";
import { handleEntitys } from "@/utils";

export default {
  components: {
    Table,
  },
  data() {
    return {
      pageList: {
        page: 1,
        rows: 20,
        count: 0,
      },
      columns: [
        {
          label: "账户",
          prop: "account",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
        },
        {
          label: "用户名",
          prop: "username",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
        },
        {
          label: "用户是否有效",
          prop: "isAlive",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
        },
        {
          label: "是否有下载权限",
          prop: "canDownload",
          feildType: "STRING",
          queryType: "LIKE",
          isnull: false,
          query: false,
          form: true,
        },
      ],
      tableData: [],
      addUserDate: {},
      dialogVisible: false,
      ruleForm: {
        account: "",
        username: "",
        isAlive: 1,
        delivery: false,
        canDownload: 0
      },
      rules: {
        account: [{ required: true, message: "请输入账号名", trigger: "blur" }],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        isAlive: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        canDownload: [
          { required: true, message: "请选择下载权限", trigger: "change" },
        ],
      },
      isEditor: false,
    };
  },
  mounted() {
    this.getPage();
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false;
    },
    switchChange(fl) {
      this.ruleForm.delivery = fl;
    },
    addBut(flag, row) {
      this.dialogVisible = true;
      this.isEditor = flag;
      if (row) {
        // row.isAlive = row.delivery ? 1 : 0;
        // this.ruleForm = row;
        let { account, username, isAlive, id, canDownload } = row;
        this.ruleForm.account = account;
        this.ruleForm.username = username;
        this.ruleForm.isAlive = isAlive;
        this.ruleForm.canDownload = canDownload;
        this.ruleForm.delivery = row.isAlive ? true : false;
        this.ruleForm.id = id;
      }
      if (!flag) {
        this.ruleForm = {
          account: "",
          username: "",
          isAlive: 1,
          delivery: false,
          canDownload: 0
        };
      }
    },
    async getPage(entity, searchButton) {
      const params = handleEntitys(entity || this.$refs.tableComponent.entitys);
      params.entity = {
        ...params.entity,
        // orgNo: this.selectData.orgNo
      };
      if (searchButton) {
          if(searchButton === 1){
              this.pageList.page = 1
              params.page = 1
              params.rows = entity.rows;
          }else if(searchButton === 2){
              params.page = entity.page;
              params.rows = this.pageList.rows
          }
      } else {
        params.page = this.pageList.page;
        params.rows = this.pageList.rows;
      }
      userGetPage(params).then((res) => {
        this.pageList.page = res.currentPage;
        this.pageList.count = res.count;
        this.tableData = res.data;
      });
    },
    async addUser() {
      let res = null;
      this.ruleForm.isAlive = this.ruleForm.delivery ? 1 : 0;
      let { account, username, isAlive, id, canDownload } = this.ruleForm;
      if (this.isEditor) {
        // 编辑用户
        res = await updateUser({ account, username, isAlive, id, canDownload });

      } else {
        // 新增用户
        res = await createUser({ account, username, isAlive, canDownload });
      }
      if (!res.code) {
        this.$message({
          message: res.data,
          type: "success",
        });
        this.isEditor && this.$store.commit("user/SET_ISCANDOWNLOAD",this.ruleForm.canDownload)
        this.dialogVisible = false;
        this.pageList.page = 1
        this.getPage();
      }
    },
    userReset(row) {
      this.$confirm("确定重置用户密码？", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await userReset(row.account);
        if (!res.code) {
          this.$message({
            message: res.data,
            type: "success",
          });
        }
      });
    },
    removeForbidden(row) {
      this.$confirm("确定要解除锁定么？", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let res = await removeForbidden(row.account);
        if (!res.code) {
          this.$message({
            message: res.data,
            type: "success",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  padding: 25px 50px 0 50px;
}
.title_div {
  margin-bottom: 20px;
}
.user_p {
  font-size: 22px;
  text-align: center;
  color: #606266;
}

::v-deep .contentBox {
  .el-table th,
  .el-table tr {
    background: #fff !important;
    color: #606266 !important;
  }
  .is-scrolling-none {
    background: #fff !important;
  }
  .hover-row {
    td {
      background: #ced8ea !important;
    }
  }
  .pagination-container {
    .el-pagination {
      .el-pager {
        .number {
          background: #fff !important;
          color: #000;
        }
        .active {
          background: #fff !important;
          color: #409eff;
        }
      }
    }
  }
  .el-table__empty-block{
    background-color: #fff;
  }
}

::v-deep .table .tableBox,
::v-deep .table .el-table th.is-leaf {
  border: 1px solid #eaeefb;
  background: transparent;
}

//分页样式
::v-deep .table .pagination-container .el-input__inner,
.table .pagination-container .el-pagination__jump,
::v-deep .table .el-pagination__total,
::v-deep .el-pagination__jump {
  color: #606266 !important;
}
</style>
<style lang="scss">
 .paginaClass{
      background: #FFF !important;
      .selected{
        color: #409EFF !important;
      }
      .el-select-dropdown__item{
        color: #606266;
      }
  }
</style>